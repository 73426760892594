import { makeStyles, Typography } from "@material-ui/core";
import MediaQuery from "react-responsive";

import SectionTitle from "../SectionTitle";
import Fractal from "../../images/fractal.png"

const AboutMe = () => {
    const classes = useStyles();
    var link = <a style={{textDecoration: "none"}} target="_blank" href="http://debruijnsequence.org/db/home">here</a>;

    return (
        <div className={classes.container}>
            <MediaQuery minWidth={1224}>
                <SectionTitle title="About Me" align="right"/>
                <div className={classes.largeDescriptionContainer}>
                    <div className={classes.largeDescription}>
                        <Typography className={classes.largeParagraph} align="justify">
                            My name is Andrew Trautrim, but you could probably figure that out from being on this website. I am a recent graduate of the Computer Science program with high honours at the University of Guelph with a specialization in Mathematics. I have a strong enthusism for mathematics and its practical applications in the real world. In my spare time I enjoy exploring the intricacies of the mathematical universe. In addition to this, I also had the opportunity to explore this enthusiasm in an academic setting. In my final year at the University of Guelph I had the privilege of working with Professor Joe Sawada on his research in universal sequence generation, culminating into a paper currently pending acceptance to ACM-SIAM Symposium on Discrete Algorithms. Furthermore, I am a contributor to the de Bruijn Sequence project led by Professor Sawada, which you can find {link}.
                        </Typography>
                        <Typography className={classes.largeParagraph} align="justify">
                            Throughout my undergraduate studies, I have developed a keen fascination with fluid dynamics and its applications in planetary sciences, particularly in the field of meteorology and oceanography. My interest in this field was initially sparked when I was exposed to a variety of classes on calculus and numerical methods. However, it wasn't until I took an advanced mathematics course on environmental transport modeling that this fascination solidified. During this course, I had the opportunity to apply both computational and mathematical techniques to analyze fluid dynamic models in a real-world setting. Coming from a background in computer science also gave me a unique perspective on how computational approaches can bridge the gap between our current models and their seemingly unknown solutions.
                        </Typography>
                        <Typography className={classes.largeParagraph} align="justify">
                            On a less nerdy note, I also enjoy being outdoors, going camping, rock climbing, mountain biking, and snowboarding. 
                        </Typography>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={575} maxWidth={1223}>
                <SectionTitle title="About Me" align="right"/>
                <div className={classes.mediumDescriptionContainer}>
                    <div className={classes.mediumDescription}>
                        <Typography className={classes.mediumParagraph} align="justify">
                            My name is Andrew Trautrim, but you could probably figure that out from being on this website. I am a recent graduate of the Computer Science program with high honours at the University of Guelph with a specialization in Mathematics. I have a strong enthusism for mathematics and its practical applications in the real world. In my spare time I enjoy exploring the intricacies of the mathematical universe. In addition to this, I also had the opportunity to explore this enthusiasm in an academic setting. In my final year at the University of Guelph I had the privilege of working with Professor Joe Sawada on his research in universal sequence generation, culminating into a paper currently pending acceptance to ACM-SIAM Symposium on Discrete Algorithms. Furthermore, I am a contributor to the de Bruijn Sequence project led by Professor Sawada, which you can find {link}.
                        </Typography>
                        <Typography className={classes.mediumParagraph} align="justify">
                            Throughout my undergraduate studies, I have developed a keen fascination with fluid dynamics and its applications in planetary sciences, particularly in the field of meteorology and oceanography. My interest in this field was initially sparked when I was exposed to a variety of classes on calculus and numerical methods. However, it wasn't until I took an advanced mathematics course on environmental transport modeling that this fascination solidified. During this course, I had the opportunity to apply both computational and mathematical techniques to analyze fluid dynamic models in a real-world setting. Coming from a background in computer science also gave me a unique perspective on how computational approaches can bridge the gap between our current models and their seemingly unknown solutions.
                        </Typography>
                        <Typography className={classes.mediumParagraph} align="justify">
                            On a less nerdy note, I also enjoy being outdoors, going camping, rock climbing, mountain biking, and snowboarding. 
                        </Typography>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={260} maxWidth={574}>
                <SectionTitle title="About Me" align="right"/>
                <div className={classes.smallDescriptionContainer}>
                    <div className={classes.smallDescription}>
                        <Typography className={classes.smallParagraph} align="justify">
                            My name is Andrew Trautrim, but you could probably figure that out from being on this website. I am a recent graduate of the Computer Science program with high honours at the University of Guelph with a specialization in Mathematics. I have a strong enthusism for mathematics and its practical applications in the real world. In my spare time I enjoy exploring the intricacies of the mathematical universe. In addition to this, I also had the opportunity to explore this enthusiasm in an academic setting. In my final year at the University of Guelph I had the privilege of working with Professor Joe Sawada on his research in universal sequence generation, culminating into a paper currently pending acceptance to ACM-SIAM Symposium on Discrete Algorithms. Furthermore, I am a contributor to the de Bruijn Sequence project led by Professor Sawada, which you can find {link}.
                        </Typography>
                        <Typography className={classes.smallParagraph} align="justify">
                            Throughout my undergraduate studies, I have developed a keen fascination with fluid dynamics and its applications in planetary sciences, particularly in the field of meteorology and oceanography. My interest in this field was initially sparked when I was exposed to a variety of classes on calculus and numerical methods. However, it wasn't until I took an advanced mathematics course on environmental transport modeling that this fascination solidified. During this course, I had the opportunity to apply both computational and mathematical techniques to analyze fluid dynamic models in a real-world setting. Coming from a background in computer science also gave me a unique perspective on how computational approaches can bridge the gap between our current models and their seemingly unknown solutions.
                        </Typography>
                        <Typography className={classes.smallParagraph} align="justify" >
                            On a less nerdy note, I also enjoy being outdoors, going camping, rock climbing, mountain biking, and snowboarding. 
                        </Typography>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        paddingBottom: "10em",
        backgroundImage: `url(${Fractal})`
    },
    largeDescriptionContainer: {
        paddingTop: "3em",
        width: "60%",
        margin: "auto"
    },
    largeDescription: {
        paddingLeft: "15em"
    },
    largeParagraph: {
        textIndent: "4em",
        marginBottom: "1em",
        color: "rgb(65,65,65)",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0em 0em 10em 5em rgba(255, 255, 255, 0.8)"
    },
    mediumDescriptionContainer: {
        paddingTop: "3em",
        width: "80%",
        margin: "auto"
    },
    mediumDescription: {
        paddingLeft: "15em"
    },
    mediumParagraph: {
        textIndent: "4em",
        marginBottom: "1em",
        color: "rgb(65,65,65)",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0em 0em 10em 5em rgba(255, 255, 255, 0.8)"
    },
    smallDescriptionContainer: {
        paddingTop: "1em",
        width: "80%",
        margin: "auto"
    },
    smallDescription: {
        paddingLeft: "6em"
    },
    smallParagraph: {
        fontSize: "0.5em",
        textIndent: "4em",
        marginBottom: "1em",
        color: "rgb(65,65,65)",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0em 0em 10em 5em rgba(255, 255, 255, 0.8)"
    },
});

export default AboutMe;
