import MetaTags from 'react-meta-tags';
import Header from "./components/Header";
import AboutMe from './components/AboutMe';
import Experience from './components/Experience';
// import Academics from './components/Academics'
//import MoreAboutMe from './components/MoreAboutMe';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <MetaTags>
        <title>Andrew Trautrim | Home Page</title>
        <meta name="description" content="Discover Andrew Trautrim's Professional Experience | Expertise in Computer Science and Mathematics | Passion for Plnetary Sciences and Fluid Dynamics" />
      </MetaTags>
      <Header />
      <Experience />
      <AboutMe />
      {/* <Academics /> */}
      <Footer />
    </div>
  );
};

export default App;
