import { makeStyles, Divider, Typography } from "@material-ui/core";
import MediaQuery from 'react-responsive';

const SectionTitle = ({ title, align }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <MediaQuery minWidth={1224}>
                <div className={classes.largeTitleBox}>
                    <Typography className={classes.largeTitle} align={align} variant="h2">{title.toUpperCase()}</Typography>
                <Divider />
                </div>
            </MediaQuery>
            <MediaQuery minWidth={575} maxWidth={1223}>
                <div className={classes.mediumTitleBox}>
                    <Typography className={classes.mediumTitle} align={align} variant="h2">{title.toUpperCase()}</Typography>
                <Divider />
                </div>
            </MediaQuery>
            <MediaQuery minWidth={260} maxWidth={574}>
                <div className={classes.smallTitleBox}>
                    <Typography className={classes.smallTitle} align={align} variant="h2">{title.toUpperCase()}</Typography>
                <Divider />
                </div>
            </MediaQuery>
        </div>
    )
};

const useStyles = makeStyles({
    container: {
        
    },
    largeTitleBox: {
        width: "60%",
        textAlign: "center",
        position: "relative",
        margin: "auto",
    },
    largeTitle: {
        fontSize: "5em"
    },
    mediumTitleBox: {
        width: "80%",
        textAlign: "center",
        position: "relative",
        margin: "auto",
    },
    mediumTitle: {
        fontSize: "3.5em"
    },
    smallTitleBox: {
        width: "80%",
        textAlign: "center",
        position: "relative",
        margin: "auto",
    },
    smallTitle: {
        fontSize: "1.5em"
    }
});

export default SectionTitle;