
export const ncrDescription = [
    "I was part of the team that developed applications for bank tellers. Using a combination of React, JavaScript, HTML, CSS, I built a functional and aesthetic user interface."
];

export const magnetForensicsDescription1 = [
    "I was part of a team that was building forensics software used to analyze already extracted information. Maintained the project's architecture using Kubernetes and Docker. Built and tested the system on a variety of host platforms such as AWS and Azure."
];

export const magnetForensicsDescription2 = [
    "I was part of the cloud forensics team which provided digit forensic software for analyzing user data hosted on cloud platforms. My main duties were to research and reverse engineering how data was stored on a variety of platforms to better automate analysis. Using the .NET framework I was able to successfully automate the extraction of data from several well known cloud platforms. "
];

export const nferenceDescription = [
    "I was in charge of datagen and data storage for an NLP preprocessing pipeline. The pipeline had to be built from scratch and designed to be run on local servers and in order to for these requirements to be met, I had to do the following: build and maintained NLP embedding cache system, coordinate and manage datastores across several database systems, e.g., S3, MongoDB, and RocksDB, and build custom distributed systems for NLP datagen and app backend using GlusterFS and Ray. As a result, precomputation reduced query times from minutes to milliseconds."    
];

export const uoguelphDescription = [
    "I assisted Professor Joe Sawada in his research in sequence generation. During my time here I co-authored a paper on sequence generation currently pending acceptance to the ACM-SIAM Symposium on Discrete Algorithms. As a result of my contributions, our hypothesis on an underlying connection within a class of sequences was proven mathematically."
];