import { useEffect, useState } from "react";
import { makeStyles, Fade, Typography } from "@material-ui/core";
import MediaQuery from 'react-responsive';

import Andrew from "../../../images/andrew2.jpg"

const PhotoBox = () => {
    const [isBoxVisible, setBoxVisibility] = useState(false);
    const [isPhotoVisible, setPhotoVisibility] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        let scrollListener = () => {
            if (window.pageYOffset > 150)
                setBoxVisibility(true);
            if (window.pageYOffset > 450)
                setPhotoVisibility(true);
        };
        window.addEventListener("scroll", scrollListener);

        return () => window.removeEventListener("scroll", scrollListener);
    });

    return (
        <Fade in={isBoxVisible} timeout={500}>
            <div className={classes.container}>
                <MediaQuery minWidth={1224}>
                    <div className={classes.largeBox}>
                        <div className={classes.largeSubBox}>
                            <Fade in={isPhotoVisible} timeout={500}>
                                <div>
                                    <img className={classes.largePhoto} src={Andrew} alt="my sexy ass self"/>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={575} maxWidth={1223}>
                <div className={classes.mediumBox}>
                        <div className={classes.mediumSubBox}>
                            <Fade in={isPhotoVisible} timeout={500}>
                                <div>
                                    <img className={classes.mediumPhoto} src={Andrew} alt="my sexy ass self"/>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={260} maxWidth={574}>
                <div className={classes.smallBox}>
                        <div className={classes.smallSubBox}>
                            <Fade in={isPhotoVisible} timeout={500}>
                                <div>
                                    <img className={classes.smallPhoto} src={Andrew} alt="my sexy ass self"/>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        </Fade>
    );
};

const useStyles = makeStyles({
    container: {

    },
    // largeSchool: {
    //     color: "#ba8900",
    //     fontStyle: "oblique"
    // },
    largeBox: {
        margin: "auto",
        backgroundColor: "rgba(255,255,255,0.8)",
        width: "22.5em",
        height: "25.5em",
    },
    largeSubBox: {
        border: "0.5em solid black",
        position: "relative",
        width: "22em",
        height: "25em",
        left: "-2.5em",
        top: "-2.5em"
    },
    largePhoto: {
        position: "relative",
        width: "16em",
        left: "4em",
        top: "4em"
    },
    mediumBox: {
        margin: "auto",
        backgroundColor: "rgba(255,255,255,0.8)",
        width: "16.875em",
        height: "19.125em",
    },
    mediumSubBox: {
        border: "0.5em solid black",
        position: "relative",
        width: "16.375em",
        height: "18.625em",
        top: "-1.8em",
        left: "-1.8em",
    },
    mediumPhoto: {
        position: "relative",
        width: "12em",
        top: "3em",
        left: "3em",
    },
    smallBox: {
        margin: "auto",
        backgroundColor: "rgba(255,255,255,0.8)",
        width: "8.4375em",
        height: "9.5625em",
    },
    smallSubBox: {
        border: "0.3em solid black",
        position: "relative",
        width: "8.1375em",
        height: "9.2625em",
        top: "-0.9em",
        left: "-0.9em",
    },
    smallPhoto: {
        position: "relative",
        width: "6em",
        top: "1.5em",
        left: "1.5em",
    },
});

export default PhotoBox;