import { makeStyles, Typography } from "@material-ui/core";
import { Parallax } from "react-parallax"; 
import MediaQuery from 'react-responsive';

import PhotoBox from "./PhotoBox";
import Blackboard from "../../images/blackboard.png";

const Header = () => {
    const classes = useStyles();

    return (
        <Parallax 
            bgImage={Blackboard}
            bgImageAlt="blackboard filled with equations"
            strength={-300}
        >
            <div className={classes.container}>
                    <MediaQuery minWidth={1224}>
                        <div className={classes.titleContainer} container>
                            <Typography align="center" variant="h3" className={classes.largeSubtitle}>Hi, I'm</Typography>
                            <Typography align="center" variant="h1" className={classes.largeTitle}>Andrew Trautrim</Typography>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col1}>
                                <PhotoBox />
                            </div>
                            <div className={classes.col2}>
                                <Typography className={classes.largeDesignation}>
                                    <b>Computer Scientist and Aspiring Mathematician</b>
                                </Typography>
                                <Typography className={classes.largeSummary}>
                                    <b>
                                        Bachelor of Computing,  University of Guelph
                                    </b>
                                    <ul style={{margin: "0"}}>
                                        <li>Computer Science major with a specialization in Mathematics</li>
                                        <li>4.0 GPA (93% cumulative average)</li>
                                    </ul>
                                </Typography>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={575} maxWidth={1223}>
                        <div className={classes.titleContainer} container>
                            <Typography align="center" variant="h3" className={classes.mediumSubtitle}>Hi, I'm</Typography>
                            <Typography align="center" variant="h1" className={classes.mediumTitle}>Andrew Trautrim</Typography>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col1}>
                                <PhotoBox />
                            </div>
                            <div className={classes.col2}>
                                <Typography className={classes.mediumDesignation}>
                                    <b>Computer Scientist and Aspiring Mathematician</b>
                                </Typography>
                                <Typography className={classes.mediumSummary}>
                                    <b>
                                        Bachelor of Computing,  University of Guelph
                                    </b>
                                    <ul style={{margin: "0"}}>
                                        <li>Computer Science major with a specialization in Mathematics</li>
                                        <li>4.0 GPA (93% cumulative average)</li>
                                    </ul>
                                </Typography>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={260} maxWidth={574}>
                        <div className={classes.titleContainer} container>
                            <Typography align="center" variant="h3" className={classes.smallSubtitle}>Hi, I'm</Typography>
                            <Typography align="center" variant="h1" className={classes.smallTitle}>Andrew Trautrim</Typography>
                        </div>
                        <div className={classes.row}>
                            <div className={classes.col1}>
                                <PhotoBox />
                            </div>
                            <div className={classes.col2}>
                                <Typography className={classes.smallDesignation}>
                                    <b>Computer Scientist and Aspiring Mathematician</b>
                                </Typography>
                                <Typography className={classes.smallSummary}>
                                    <b>
                                        Bachelor of Computing, University of Guelph
                                    </b>
                                    <ul style={{margin: "0"}}>
                                        <li>Computer Science major with a specialization in Mathematics</li>
                                        <li>4.0 GPA (93% cumulative average)</li>
                                    </ul>
                                </Typography>
                            </div>
                        </div>
                    </MediaQuery>
                </div>
        </Parallax>
    );
}

const useStyles = makeStyles({
    container: {
        backgroundImage: `linear-gradient(rgba(41, 41, 41, 0.6), rgba(41, 41, 41, 0.6), rgba(150, 150, 150, 0.8), rgb(255, 255, 255))`,
        paddingBottom: "20em",
    },
    titleContainer: {
        paddingTop: "22em",
        paddingBottom: "28em"
    },
    row: {
        width: "100%",
        content: "",
        display: "table",
        clear: "both"
    },
    col1: {
        float: "left",
        width: "45%",
    },
    col2: {
        float: "left",
        width: "55%",
    },
    largeTitle: {
        textAlign: "center",
        color: "white",
        fontSize: "7em"
    },
    largeSubtitle: {
        textAlign: "center",
        color: "white",
        fontFamily: "Italianno",
        fontSize: "3.5em",
        marginBottom: "-0.4em"
    },
    largeDesignation: {
        marginRight: "1em",
        fontSize: "3em",
        color: "white",
        lineHeight: "1.25em"
    },
    largeSummary: {
        marginRight: "5em",
        fontSize: "1.5em"
    },
    mediumTitle: {
         textAlign: "center",
         color: "white",
         fontSize: "5em"
     },
     mediumSubtitle: {
         textAlign: "center",
         color: "white",
         fontFamily: "Italianno",
         fontSize: "2.5em",
         marginBottom: "-0.3em"
     },
     mediumDesignation: {
         marginRight: "1em",
         fontSize: "2.5em",
         color: "white",
         lineHeight: "1.25em"
     },
     mediumSummary: {
         marginRight: "5em",
         fontSize: "1em"
     },
     smallTitle: {
          textAlign: "center",
          color: "white",
          fontSize: "2em"
      },
      smallSubtitle: {
          textAlign: "center",
          color: "white",
          fontFamily: "Italianno",
          fontSize: "1em",
          marginBottom: "-0.1em"
      },
      smallDesignation: {
          marginRight: "1em",
          fontSize: "1em",
          color: "white",
          lineHeight: "1.25em"
      },
      smallSummary: {
          marginRight: "5em",
          fontSize: "0.75em"
      },
})

export default Header;
