import { Box, makeStyles, Typography } from "@material-ui/core";

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography className={classes.name}><Box fontWeight="fontWeightBold">Andrew Trautrim</Box></Typography>
            <Typography className={classes.text}>University of Guelph</Typography>
            <Typography className={classes.text}>Computer Science</Typography>
            <Typography className={classes.text}>atrautrim7@gmail.com</Typography>
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        color: "white",
        backgroundColor: "black",
        padding: "5em 0em",
        textAlign: "center"
    },
    name: {
        fontSize: "1.5em"
    },
    text: {
        margin: "0.75em 0em",
        font: "1rem Inconsolata, monospace"
    }
})

export default Footer;
