import { useState, useEffect } from "react";
import { 
    makeStyles,
    Fade,
    Typography
} from "@material-ui/core";
import SectionTitle from "../SectionTitle";
import MediaQuery from 'react-responsive';
import { useMediaQuery } from 'react-responsive';

import NCR from "../../images/NCR.png";
import MagnetForensics from "../../images/magnetForensics.png";
import nference from "../../images/nference.png";
import uoguelph from "../../images/uoguelph.jpeg";
import { ncrDescription, magnetForensicsDescription1, magnetForensicsDescription2, nferenceDescription, uoguelphDescription } from "./constants"; 

const Experience = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SectionTitle title="Experience" align="left"/>
            <Position companyLogo={uoguelph} company="University of Guelph" position="Research Assistant" dateRange="May 2023 - July 2023" description={uoguelphDescription}/>
            <Position companyLogo={nference} company="nference" position="MLOps Engineer" dateRange="May 2022 - Aug 2022" description={nferenceDescription}/>
            <Position companyLogo={MagnetForensics} company="Magnet Forensics" position="Full-stack Developer" dateRange="Jan 2022 - Apr 2022" description={magnetForensicsDescription2}/>
            <Position companyLogo={MagnetForensics} company="Magnet Forensics" position="Full-stack Developer" dateRange="May 2021 - Aug 2021" description={magnetForensicsDescription1}/>
            <Position companyLogo={NCR} company="NCR" position="Front-end Developer" dateRange="Sept 2020 - Dec 2020" description={ncrDescription}/>
        </div>
    );
};

const Position = ({ companyLogo, company, position, dateRange, description }) => {
    // const [isVisible, setVisibility] = useState(false);
    const classes = useStyles();

    // const isLarge = useMediaQuery({ minWidth: 1224 });
    // const isMedium = useMediaQuery({ minWidth: 575, maxWidth: 1223 });
    // const isSmall = useMediaQuery({ minWidth: 260, maxWidth: 574 });
    // const offset = isLarge ? 700 : (isMedium ? 800 : 900);

    // useEffect(() => {
    //     let scrollListener = () => {
    //         if (document.getElementById(description) === null)
    //             setVisibility(true);
    //         if (document.getElementById(description) != null && window.pageYOffset > document.getElementById(description).offsetTop - offset)
    //             setVisibility(true);
    //     };
    //     window.addEventListener("scroll", scrollListener);

    //     return () => window.removeEventListener("scroll", scrollListener);
    // });

    return (
        <div>
            <MediaQuery minWidth={1224}>
                <div id={description} className={[classes.row, classes.largeSectionContainer].join(' ')}>
                    <div className={classes.col}>
                        <Typography className={classes.companyName}>{company}</Typography>
                        <div className={classes.row} style={{paddingBottom: "1em"}}>
                            <div className={classes.col}>
                                <Typography>{position}</Typography>
                            </div>
                            <div className={classes.col} style={{textAlign: "right"}}>
                                <Typography>{dateRange}</Typography>
                            </div>
                        </div>
                        <Typography  className={classes.description}>{description}</Typography>
                    </div>
                    <div className={classes.col}>
                        <img className={classes.companyLogo} src={companyLogo} alt="company logo"/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={575} maxWidth={1223}>
            <div id={company} className={[classes.row, classes.mediumSectionContainer].join(' ')}>
                    <div className={classes.col}>
                        <Typography className={classes.companyName}>{company}</Typography>
                        <div className={classes.row} style={{paddingBottom: "1em"}}>
                            <div className={classes.col}>
                                <Typography>{position}</Typography>
                            </div>
                            <div className={classes.col} style={{textAlign: "right"}}>
                                <Typography>{dateRange}</Typography>
                            </div>
                        </div>
                        <Typography  className={classes.description}>{description}</Typography>
                    </div>
                    <div className={classes.col}>
                        <img className={classes.companyLogo} src={companyLogo} alt="company logo"/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={260} maxWidth={574}>
            <div id={company} className={[classes.row, classes.smallSectionContainer].join(' ')}>
                    <div className={classes.col}>
                        <Typography className={classes.smallCompanyName}>{company}</Typography>
                        <div className={classes.row} style={{paddingBottom: "0.3em"}}>
                            <div className={classes.col}>
                                <Typography className={classes.smallPosition}>{position}</Typography>
                            </div>
                            <div className={classes.col} style={{textAlign: "right"}}>
                                <Typography className={classes.smallPosition}>{dateRange}</Typography>
                            </div>
                        </div>
                        <Typography  className={classes.smallDescription}>{description}</Typography>
                    </div>
                    <div className={classes.col}>
                        <img className={classes.smallCompanyLogo} src={companyLogo} alt="company logo"/>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

const useStyles = makeStyles({
    container: {
        padding: "10em 0"
    },
    row: {
        width: "100%",
        content: "",
        display: "table",
        clear: "both"
    },
    col: {
        float: "left",
        width: "50%",
    },
    description: {
        textIndent: "4em",
        textAlign: "justify",
        color: "rgb(65,65,65)",
    },
    companyName: {
        textAlign: "right",
        fontWeight: "bold",
        fontSize: "1.5em"
    },
    companyLogo: {
        maxWidth: "10em",
        maxHeight: "10em",
        borderRadius: "1em",
        boxShadow: "0.3em 0.3em 0.5em #888888",
        marginLeft: "3em"
    },
    largeSectionContainer: {
        width: "60%",
        textAlign: "left",
        margin: "auto",
        padding: "3em 0"
    },
    mediumSectionContainer: {
        width: "80%",
        textAlign: "left",
        margin: "auto",
        padding: "3em 0"
    },
    smallSectionContainer: {
        width: "80%",
        textAlign: "left",
        margin: "auto",
        padding: "1em 0"
    },
    smallCompanyName: {
        textAlign: "right",
        fontWeight: "bold",
        fontSize: "0.75em"
    },
    smallCompanyLogo: {
        maxWidth: "5em",
        maxHeight: "5em",
        borderRadius: "0.5em",
        boxShadow: "0.3em 0.3em 0.5em #888888",
        marginLeft: "3em"
    },
    smallDescription: {
        textIndent: "4em",
        textAlign: "justify",
        fontSize: "0.45em",
        color: "rgb(65,65,65)",
    },
    smallPosition: {
        fontSize: "0.45em"
    }
})

export default Experience;
